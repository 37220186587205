import { ContextType, NativeResources } from '@ariksa/inventory-core';

import { IconType, IconTypes } from 'components/Icons';

import AzureADGroupIcon from './blackIcons/azure-ad-group.png';
import AzureADUserIcon from './blackIcons/azure-ad-user.png';
import InternetIcon from './blackIcons/internet.png';
import SnowflakeIcon from './blackIcons/snowflake.png';
import AccountRootIcon from './whiteIcons/Account-Root-White-128x128.png';
import AWSAccountIcon from './whiteIcons/Account-White-128x128.png';
import AccountIcon from './whiteIcons/Account-White-128x128.png';
import AgentIcon from './whiteIcons/Agent-White-128x128.png';
import AmazonMachineImageIcon from './whiteIcons/Ami-White-128x128.png';
import APIGatewayRestAPIIcon from './whiteIcons/Api-Gateway-Api-White-128x128.png';
import ApiIcon from './whiteIcons/Api-White-128x128.png';
import AzureADOrganizationIcon from './whiteIcons/Azure-Ad-White-128x128.png';
import BackupIcon from './whiteIcons/Backup-White-128x128.png';
import BruteForceAttack from './whiteIcons/brute-force-attack-white.png';
import ACMCertificateIcon from './whiteIcons/Certificate-White-128x128.png';
import CloudFrontIcon from './whiteIcons/Cloudfront-Distribution-White-128x128.png';
import CloudTrailIcon from './whiteIcons/Cloudtrail-White-128x128.png';
import CloudWatchLogGroupIcon from './whiteIcons/Cloudwatch-Monitoring-White-128x128.png';
import ComputeIcon from './whiteIcons/Compute-White-128x128.png';
import ConfigurationIcon from './whiteIcons/Configuration-White-512x512.png';
import ConsoleWhite from './whiteIcons/ConsoleWhite.png';
import ContainerIcon from './whiteIcons/Container-White-128x128.png';
import CrossAccount from './whiteIcons/cross-account-white.png';
import DHCPOptionsIcon from './whiteIcons/Dhcp-Options-White-128x128.png';
import DocumentDBClusterIcon from './whiteIcons/Documentdb-Cluster-White-128x128.png';
import DocumentDBIcon from './whiteIcons/Documentdb-Mongodb-White-128x128.png';
import DynamoDBIcon from './whiteIcons/Dynamodb-White-128x128.png';
import EbsIcon from './whiteIcons/Ebs-Data-White-128x128.png';
import EBSSnapshotIcon from './whiteIcons/Ebs-Snapshot-White-128x128.png';
import EC2AutoScalingGroupIcon from './whiteIcons/Ec2-Asg-White-128x128.png';
import SystemsManagerDocumentIcon from './whiteIcons/Ec2-Ssm-Document-White-128x128.png';
import EC2LaunchTemplateIcon from './whiteIcons/Ec2-Template-White-128x128.png';
import EcrImageIcon from './whiteIcons/Ecr-Image-White-128x128.png';
import ECSClusterIcon from './whiteIcons/Ecs-Cluster-White-128x128.png';
import ElasticContainerServiceIcon from './whiteIcons/Ecs-Service-White-128x128.png';
import ECSServiceIcon from './whiteIcons/Ecs-Service-White-128x128.png';
import Egress from './whiteIcons/Egress.png';
import ElasticBeanstalkIcon from './whiteIcons/Elastic-Beanstalk-Application-Orchestration-White-128x128.png';
import ElasticLoadBalancerIcon from './whiteIcons/Elastic-Load-Balancer-White-128x128.png';
import ElasticMapReduceIcon from './whiteIcons/Elastic-Map-Reduce-White-128x128.png';
import ElasticCacheReplicationGroupIcon from './whiteIcons/Elasticache-Replication-Group-White-128x128.png';
import ElastiCacheIcon from './whiteIcons/Elasticache-White-128x128.png';
import ElasticSearchIcon from './whiteIcons/Elasticsearch-White-128x128.png';
import ExploitableIcon from './whiteIcons/exploitable.png';
import ElasticFileSystemIcon from './whiteIcons/Folder-White-128x128.png';
import IAMGroupIcon from './whiteIcons/Group-White-128x128.png';
import HighPrivileges from './whiteIcons/high-privileges-white.png';
import CloudFormationIcon from './whiteIcons/Infrastructure-As-Code-Cloudformation-White-128x128.png';
import Ingress from './whiteIcons/Ingress.png';
import InternetAccessibleIcon from './whiteIcons/internet-accessible-white.png';
import InternetGatewayIcon from './whiteIcons/Internet-Gateway-White-128x128.png';
import ElasticIPIcon from './whiteIcons/Ip-Address-White-128x128.png';
import KMSIcon from './whiteIcons/Kms-Key-White-128x128.png';
import ElasticKubernetesServiceIcon from './whiteIcons/Kubernetes-White-128x128.png';
import LeakedCredential from './whiteIcons/leaked-credential-128x128.png';
import LibrariesIcon from './whiteIcons/library-128x128.png';
import MaliciousIngress from './whiteIcons/malicious-ingress-white.png';
import Namespace from './whiteIcons/Namespace-White-128x128.png';
import NATGatewayIcon from './whiteIcons/NAT-Gateway-White-128x128.png';
import NeptuneClusterIcon from './whiteIcons/Neptune-Cluster-White-128x128.png';
import NeptuneIcon from './whiteIcons/Neptune-White-128x128.png';
import NetworkAccessControlIcon from './whiteIcons/Network-ACL-White-128x128.png';
import NetworkInterfaceIcon from './whiteIcons/Network-Interface-White-128x128.png';
import OktaGroupIcon from './whiteIcons/Okta-White-128x128.png';
import OktaUserIcon from './whiteIcons/Okta-White-128x128.png';
import OktaOrganizationIcon from './whiteIcons/Okta-White-128x128.png';
import OrganizationUnitIcon from './whiteIcons/Organization-Unit-White-128x128.png';
import AWSOrganizationIcon from './whiteIcons/Organization-White-128x128.png';
import VPCPeeringConnectionIcon from './whiteIcons/P2p-White-128x128.png';
import PermissionsIcon from './whiteIcons/Permissions-Actions-White-128x128.png';
import Pods from './whiteIcons/Pods-White-128x128.png';
import PolicyIcon from './whiteIcons/Policy-White-128x128.png';
import PrefixListIcon from './whiteIcons/Prefix-List-White-128x128.png';
import RDSCertificateIcon from './whiteIcons/Rds-Certificate-White-128x128.png';
import RDSClusterIcon from './whiteIcons/Rds-Cluster-White-128x128.png';
import RDSSnapshotIcon from './whiteIcons/Rds-Snapshot-White-128x128.png';
import RDSIcon from './whiteIcons/Rds-White-512x512.png';
import RedshiftClusterIcon from './whiteIcons/Redshift-White-128x128.png';
import IAMRoleIcon from './whiteIcons/Role-White.png';
import SubnetRouteTableIcon from './whiteIcons/Route-Table-White-128x128.png';
import TransitGatewayRouteTableIcon from './whiteIcons/Route-Table-White-128x128.png';
import RouteIcon from './whiteIcons/Route-White-128x128.png';
import Route53HealthCheckIcon from './whiteIcons/Route53-Health-Check-White-128x128.png';
import Route53HostedZoneIcon from './whiteIcons/Route53-Hosted-Zone-White-128x128.png';
import Route53RecordSet from './whiteIcons/Route53-Record-Set-White-128x128.png';
import S3Icon from './whiteIcons/S3-Object-Storage-White-128x128.png';
import SagemakerEndpointsIcon from './whiteIcons/Sagemaker-Machine-Learning-White-128x128.png';
import SagemakerNotebookInstancesIcon from './whiteIcons/Sagemaker-Machine-Learning-White-128x128.png';
import SecretIcon from './whiteIcons/Secrets-Password-White-128x128.png';
import SecurityGroupIcon from './whiteIcons/Security-Group-White-128x128.png';
import DatabaseSecurityGroupIcon from './whiteIcons/Security-Group-White-128x128.png';
import CriticalData from './whiteIcons/sensitive-data-white.png';
import AWSLambdaIcon from './whiteIcons/Serverless-White-128x128.png';
import ResourceTypeIcons from './whiteIcons/services-white.png';
import SeverityIcon from './whiteIcons/SeverityIcon.png';
import SNSIcon from './whiteIcons/Sns-White-128x128.png';
import SQLDatabaseIcon from './whiteIcons/Sql-Database-White-128x128.png';
import SQSIcon from './whiteIcons/Sqs-White-128x128.png';
import SSHKeyPairIcon from './whiteIcons/Ssh-Key-Pair-White-128x128.png';
import SubnetIcon from './whiteIcons/Subnet-White-128x128.png';
import TransitGatewayAttachmentIcon from './whiteIcons/Transit-Gateway-Attachment-White-128x128.png';
import UnencryptedDiskIcon from './whiteIcons/unencrypted-disk-white.png';
import IAMUserIcon from './whiteIcons/User-White-128x128.png';
import EC2Icon from './whiteIcons/Virtual-Machine-White-128x128.png';
import VpcFlowLogIcon from './whiteIcons/Vpc-Flow-Log-White-128x128.png';
import VirtualPrivateCloudIcon from './whiteIcons/Vpc-White-128x128.png';
import VpnGatewayIcon from './whiteIcons/Vpn-Gateway-White-128x128.png';
import VpnIcon from './whiteIcons/Vpn-White-128x128.png';
import VulnerabilityIcon from './whiteIcons/Vulnerability-White-128x128.png';
import WAFIcon from './whiteIcons/Web-Application-Firewall-(WAF)-White-128x128.png';

export const getWhiteMapIcon = (type: IconType) => {
  switch (type) {
    case IconTypes.Libraries:
      return LibrariesIcon;
    case IconTypes.Credential:
      return LeakedCredential;
    case ContextType.Exploitable:
      return ExploitableIcon;
    case ContextType.UnencryptedData:
    case ContextType.UnencryptedDisk:
      return UnencryptedDiskIcon;
    case ContextType.InternetAccess:
      return InternetAccessibleIcon;
    case ContextType.CriticalVulnerability:
      return VulnerabilityIcon;
    case ContextType.HighVulnerability:
      return VulnerabilityIcon;
    case ContextType.SensitiveData:
      return CriticalData;
    case ContextType.BruteForceAttack:
      return BruteForceAttack;
    case ContextType.CrossAccount:
      return CrossAccount;
    case ContextType.Privileged:
      return HighPrivileges;
    case ContextType.MaliciousIngress:
      return MaliciousIngress;
    case ContextType.CredentialFound:
    case IconTypes.KeyManagementService:
    case NativeResources.IamUserAccessKey:
    case IconTypes.AccessKeys:
    case NativeResources.GcpCloudKms:
      return KMSIcon;
    case NativeResources.IamPolicy:
    case NativeResources.IamInlinePolicy:
    case NativeResources.AsgResourcePolicy:
    case NativeResources.AccountPasswordIdentityPolicy:
    case NativeResources.OrganizationPolicy:
    case NativeResources.ResourcePolicy:
    case NativeResources.GcpiamPolicy:
    case NativeResources.GcpResourcePolicy:
    case NativeResources.GcpOrgPolicyService:
    case IconTypes.Policy:
      return PolicyIcon;
    case IconTypes.Account:
    case IconTypes.GcpServiceAccount:
      return AccountIcon;
    case IconTypes.Snowflake:
    case IconTypes.SnowflakeTables:
    case IconTypes.SnowflakeRoles:
    case IconTypes.SnowflakePrivileges:
    case IconTypes.SnowflakeUsers:
    case IconTypes.SnowflakeDatabases:
    case IconTypes.SnowflakeSchemas:
      return SnowflakeIcon;
    case NativeResources.AccountRoot:
      return AccountRootIcon;
    case NativeResources.AwsConfigRule:
      return ConfigurationIcon;
    case NativeResources.AcmCertificate:
    case NativeResources.GcpsqlsslCerts:
      return ACMCertificateIcon;
    case NativeResources.AmazonMachineImage:
    case NativeResources.ElasticContainerRegistry:
    case NativeResources.GcpImage:
      return AmazonMachineImageIcon;
    case NativeResources.ApiGatewayRestApi:
    case NativeResources.GcpapiGateway:
      return APIGatewayRestAPIIcon;
    case NativeResources.AwsAccount:
      return AWSAccountIcon;
    case NativeResources.LambdaFunction:
    case NativeResources.GcpCloudFunctions:
      return AWSLambdaIcon;
    case NativeResources.AwsOrganization:
    case NativeResources.GcpOrganization:
      return AWSOrganizationIcon;
    case NativeResources.GcpFolder:
    case NativeResources.OrganizationUnit:
      return OrganizationUnitIcon;
    case NativeResources.AzureAdGroup:
      return AzureADGroupIcon;
    case NativeResources.AzureAdOrganization:
      return AzureADOrganizationIcon;
    case NativeResources.AzureAdUser:
      return AzureADUserIcon;
    case NativeResources.BackupPlan:
    case NativeResources.GcpFilestoreBackup:
      return BackupIcon;
    case NativeResources.CloudFormation:
    case NativeResources.GcpCloudDeploymentManager:
      return CloudFormationIcon;
    case NativeResources.CloudFront:
      return CloudFrontIcon;
    case NativeResources.CloudTrail:
    case NativeResources.GcpCloudLogMetrics:
      return CloudTrailIcon;
    case NativeResources.CloudWatchLogGroup:
    case NativeResources.CloudWatchAlarms:
    case NativeResources.CloudWatchLogs:
    case NativeResources.CloudWatchMetrics:
    case NativeResources.CloudWatchMetricAlarms:
      return CloudWatchLogGroupIcon;
    case NativeResources.Container:
    case NativeResources.EcsContainerInstance:
    case NativeResources.GcpContainer:
      return ContainerIcon;
    case NativeResources.DatabaseSecurityGroup:
      return DatabaseSecurityGroupIcon;
    case NativeResources.DhcpOptions:
      return DHCPOptionsIcon;
    case NativeResources.DocumentDb:
    case NativeResources.GcpFirestore:
      return DocumentDBIcon;
    case NativeResources.DocumentDbCluster:
      return DocumentDBClusterIcon;
    case NativeResources.DynamoDb:
      return DynamoDBIcon;
    case NativeResources.ElasticBlockStorage:
    case NativeResources.GcpPersistentDisk:
      return EbsIcon;
    case NativeResources.EbsSnapshot:
      return EBSSnapshotIcon;
    case NativeResources.Ec2Instance:
    case NativeResources.GcpComputeEngine:
    case NativeResources.GcpCloudTpus:
      return EC2Icon;
    case IconTypes.EcrImage:
      return EcrImageIcon;
    case NativeResources.SnowflakeUsers:
      return SnowflakeIcon;
    case NativeResources.SnowflakeRoles:
      return SnowflakeIcon;
    case NativeResources.SnowflakePrivileges:
      return SnowflakeIcon;
    case NativeResources.Ec2AutoScalingGroup:
    case NativeResources.GcpComputeEngineAutoscaler:
      return EC2AutoScalingGroupIcon;
    case NativeResources.Ec2LaunchTemplate:
      return EC2LaunchTemplateIcon;
    case NativeResources.EcsCluster:
      return ECSClusterIcon;
    case NativeResources.EcsService:
      return ECSServiceIcon;
    case NativeResources.ElasticBeanstalk:
      return ElasticBeanstalkIcon;
    case NativeResources.ElasticContainerService:
      return ElasticContainerServiceIcon;
    case NativeResources.ElasticFileSystem:
    case NativeResources.GcpFilestore:
      return ElasticFileSystemIcon;
    case NativeResources.ElasticIp:
      return ElasticIPIcon;
    case NativeResources.ElasticKubernetesService:
    case NativeResources.Gcpgke:
      return ElasticKubernetesServiceIcon;
    case NativeResources.ElasticLoadBalancer:
    case NativeResources.ElasticLoadBalancerv2:
    case NativeResources.GcpurlMaps:
      return ElasticLoadBalancerIcon;
    case NativeResources.ElasticMapReduce:
      return ElasticMapReduceIcon;
    case NativeResources.ElastiCache:
    case NativeResources.GcpMemorystoreRedis:
      return ElastiCacheIcon;
    case NativeResources.ElastiCacheReplicationGroup:
      return ElasticCacheReplicationGroupIcon;
    case NativeResources.ElasticSearch:
      return ElasticSearchIcon;
    case NativeResources.TransitGatewayAttachment:
      return TransitGatewayAttachmentIcon;
    case NativeResources.IamGroup:
    case NativeResources.GcpGroup:
      return IAMGroupIcon;
    case NativeResources.IamRole:
    case NativeResources.GcpRole:
      return IAMRoleIcon;
    case NativeResources.IamUser:
    case NativeResources.GcpUser:
      return IAMUserIcon;
    case NativeResources.InternetGateway:
      return InternetGatewayIcon;
    case NativeResources.NatGateway:
    case NativeResources.GcpCloudNat:
      return NATGatewayIcon;
    case NativeResources.Neptune:
      return NeptuneIcon;
    case NativeResources.NeptuneCluster:
      return NeptuneClusterIcon;
    case NativeResources.NetworkAcl:
      return NetworkAccessControlIcon;
    case NativeResources.ElasticNetworkInterface:
    case NativeResources.VirtualInterface:
    case NativeResources.GcpNetworkInterface:
      return NetworkInterfaceIcon;
    case NativeResources.OktaGroup:
      return OktaGroupIcon;
    case NativeResources.OktaOrganization:
      return OktaOrganizationIcon;
    case NativeResources.OktaUser:
      return OktaUserIcon;
    case NativeResources.PrefixList:
      return PrefixListIcon;
    case IconTypes.Permission:
    case ContextType.ExcessPermissions:
    case 'Permission Type':
    case 'PermissionType':
      return PermissionsIcon;
    case NativeResources.RelationalDatabaseService:
    case NativeResources.GcpCloudSql:
      return RDSIcon;
    case NativeResources.RdsCertificate:
      return RDSCertificateIcon;
    case NativeResources.RdsCluster:
      return RDSClusterIcon;
    case NativeResources.RdsSnapshot:
    case NativeResources.RdsClusterSnapshot:
      return RDSSnapshotIcon;
    case NativeResources.RedshiftCluster:
    case NativeResources.GcpBigQuery:
      return RedshiftClusterIcon;
    case NativeResources.Route53HealthCheck:
      return Route53HealthCheckIcon;
    case NativeResources.Route53HostedZone:
    case IconTypes.Route53SubDomain:
    case NativeResources.GcpCloudDnsZone:
    case NativeResources.GcpCloudDomainsRegistration:
      return Route53HostedZoneIcon;
    case NativeResources.SimpleStorageService:
    case NativeResources.GcpBucket:
      return S3Icon;
    case NativeResources.SagemakerEndpoints:
      return SagemakerEndpointsIcon;
    case NativeResources.SagemakerNotebookInstances:
      return SagemakerNotebookInstancesIcon;
    case NativeResources.Secret:
    case NativeResources.GcpSecretManager:
    case NativeResources.AzureSecrets:
      return SecretIcon;
    case NativeResources.SecurityGroup:
    case NativeResources.GcpCloudFirewall:
      return SecurityGroupIcon;
    case NativeResources.SimpleNotificationService:
    case NativeResources.GcpPubSubLite:
      return SNSIcon;
    case NativeResources.SimpleQueueService:
    case NativeResources.GcpCloudTasksQueue:
      return SQSIcon;
    case NativeResources.SshKeyPair:
      return SSHKeyPairIcon;
    case NativeResources.Subnet:
    case NativeResources.GcpSubnetwork:
      return SubnetIcon;
    case NativeResources.SubnetRouteTable:
      return SubnetRouteTableIcon;
    case NativeResources.SystemsManager:
      return AgentIcon;
    case NativeResources.SystemsManagerDocument:
      return SystemsManagerDocumentIcon;
    case NativeResources.TransitGatewayRouteTable:
      return TransitGatewayRouteTableIcon;
    case NativeResources.VirtualPrivateCloud:
    case NativeResources.GcpVirtualPrivateCloud:
      return VirtualPrivateCloudIcon;
    case NativeResources.VpcPeeringConnection:
    case NativeResources.TransitGatewayConnect:
      return VPCPeeringConnectionIcon;
    case NativeResources.AwsVirtualPrivateNetwork:
      return VpnIcon;
    case NativeResources.VpcFlowLogs:
      return VpcFlowLogIcon;
    case NativeResources.VpnGateway:
    case NativeResources.GcpvpnGateway:
      return VpnGatewayIcon;
    case NativeResources.Wafv1:
    case NativeResources.Wafv2:
    case NativeResources.WafRegional:
      return WAFIcon;
    case NativeResources.GcpRoute:
      return RouteIcon;
    case IconTypes.Internet:
      return InternetIcon;
    case IconTypes.Route53RecordSet:
      return Route53RecordSet;
    case IconTypes.Pod:
      return Pods;
    case IconTypes.Namespace:
      return Namespace;
    case IconTypes.Ingress:
      return Ingress;
    case IconTypes.Egress:
      return Egress;
    case IconTypes.SeverityMedium:
    case IconTypes.SeverityCritical:
    case IconTypes.SeverityLow:
      return SeverityIcon;
    case IconTypes.Api:
      return ApiIcon;
    case IconTypes.Console:
      return ConsoleWhite;
    case IconTypes.Resource:
      return ComputeIcon;
    case IconTypes.ResourceType:
    case 'ResourceType':
      return ResourceTypeIcons;
    case NativeResources.GcpsqlInstances:
      return SQLDatabaseIcon;
    default:
      return '';
  }
};
