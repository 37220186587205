import React, { FC } from 'react';

import { Box, Center, Flex, HStack, Stack } from '@chakra-ui/react';
import { map } from 'lodash';
import { useHistory } from 'react-router-dom';

import { PrimaryButton } from 'components/DataEntry';
import { contentStyles } from 'components/FeatureDemo/styles';
import {
  AriksaLogoIcon,
  AutomationIcon,
  CloudIcon,
  DataScanIcon,
  HealthIcon,
  TriangleRightIcon,
  VulnerabilityIcon,
} from 'components/Icons';

import { customTheme } from '../../../../theme';

interface ISetup {
  onClose();

  prev();
}

const COLOR = '#5c57a0';

export const Setup: FC<ISetup> = props => {
  const { onClose } = props;
  const history = useHistory();
  const list = [
    {
      header: 'Onboard Cloud Accounts',
      description: [
        'To onboard cloud accounts, you must have access cloud login to create a trusted role for Ariksa.',
        'It takes just a couple of mins for this process',
      ],
      icon: <CloudIcon />,
    },
    {
      header: 'Check Cloud Governance Score',
      description: [
        'Ariksa automatically evaluates your account for best practices that ensure robust account governance. ',
        'Check your score from the account menu',
      ],
      icon: <HealthIcon />,
    },
    {
      header: 'Deploy Outpost for Vulnerabilities',
      description: [
        'Deploy Ariksa Outpost to enable 100% coverage of workloads to get run-time view of OS, library and app vulnerabilities.',
        'There are no agents!',
      ],
      icon: <VulnerabilityIcon />,
    },
    {
      header: 'Deploy Outpost for Data',
      description: [
        'Deploy Ariksa Outpost in every region that you have deployed data sources.',
        'Add data sources for automatic data classification and to gain visibility into data access risks',
      ],
      icon: <DataScanIcon p={1.5} />,
    },
    {
      header: 'Automate workflows for response',
      description: [
        'Set up workflows to perform criteria-based routing of issues as tickets or ' +
          'notifications to people who need to take actions – drive efficiency through automation',
      ],
      icon: <AutomationIcon pt={0.5} />,
    },
  ];

  return (
    <Stack {...contentStyles.root} w="1550px">
      <Flex {...contentStyles.logoWrapper}>
        {/* Logo */}
        <Center my="auto" {...contentStyles.logo}>
          <AriksaLogoIcon />
        </Center>
      </Flex>
      <Stack {...contentStyles.content}>
        <Box>Quick overview of how you can make the most of Ariksa</Box>
        <HStack justify="space-between" w="full" spacing={2}>
          {map(list, (o, index) => (
            <>
              <Stack spacing={0} h="full">
                <Box
                  w="14.5rem"
                  h="full"
                  border="1px solid"
                  borderColor={COLOR}
                  borderRadius={6}
                  bg="white"
                  overflow="visible"
                  pt={7}
                  pb={7}
                  pl={7}
                  pr={6}
                  boxShadow={`1px 6px 6px ${customTheme.colors.gray['100']}`}
                >
                  <Stack spacing={4} w="full">
                    <HStack
                      fontWeight={600}
                      fontSize="md"
                      color={COLOR}
                      w="full"
                      align="flex-start"
                    >
                      <Box boxSize={10} pt={1}>
                        <Center>{o.icon}</Center>
                      </Box>
                      <Box>{o.header}...</Box>
                    </HStack>
                    <Box borderBottom="1px solid" borderColor={COLOR} />
                    <Stack h="full" pt={2}>
                      {map(o.description, d => (
                        <Box fontSize="sm">{d}</Box>
                      ))}
                    </Stack>
                  </Stack>
                </Box>
              </Stack>
              {index !== 4 && (
                <Box color={COLOR}>
                  <TriangleRightIcon />
                </Box>
              )}
            </>
          ))}
        </HStack>
        <Flex {...contentStyles.buttonGroup} gap={6}>
          <PrimaryButton size={'md'} onClick={() => props.prev()}>
            PREV
          </PrimaryButton>
          <PrimaryButton
            {...contentStyles.button}
            onClick={() => {
              onClose();
              history.push('/setup/accounts');
            }}
          >
            GET STARTED
          </PrimaryButton>
        </Flex>
      </Stack>
    </Stack>
  );
};
