import React, { FC, ReactNode } from 'react';

import { Box, StackProps, Stack } from '@chakra-ui/react';

interface Props extends StackProps {
  label: string | ReactNode;
  value?: any;
}

export const DetailsIdentifier: FC<Props> = props => {
  const { label, value, ...rest } = props;
  return (
    <Stack spacing={2} isInline {...rest}>
      <Box fontWeight={600}>{label}:</Box>
      <Box flex={1} color="gray.300">
        {value ? value : '-'}
      </Box>
    </Stack>
  );
};
