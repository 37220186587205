import { AccountType } from '@ariksa/cloud-account';
import { CloudProviders, RepositoryService } from '@ariksa/cloud-account';
import { ComplianceStandards } from '@ariksa/compliance-policies';
import { CloudEnvironmentType } from '@ariksa/compliance-policies/api';
import { SupportedServices } from '@ariksa/data-scanning';
import {
  ContextType,
  Categories,
  NativeResources,
  Resources,
} from '@ariksa/inventory-core';
import { Clients } from '@ariksa/notification';
import { ReportTypes } from '@ariksa/reporting';
import { ApplicationEnum } from '@ariksa/scan-analysis';

export const CustomIconTypes = {
  LeakedCredential: 'LeakedCredential',
  Libraries: 'Libraries',
  BlockPublicAcls: 'Block public acls',
  IgnorePublicAcls: 'Ignore public acls',
  BlockPublicAcl: 'Block public acl',
  BlockPublicPolicy: 'Block public policy',
  RestrictPublicBuckets: 'Restrict public buckets',
  DataProtection: 'Data protection',
  ObjectOwnership: 'Object ownership',
  Api: 'Api',
  AccessKeys: 'AccessKeys',
  Endpoint: 'Endpoint',
  Access: 'Access',
  Route53TrafficPolicy: 'Route53TrafficPolicy',
  Route53RecordSet: 'Route53RecordSet',
  SNSSubscription: 'SNSSubscription',
  SNSTopic: 'SNSTopic',
  Sagemaker: 'Sagemaker',
  ECSTask: 'ECSTask',
  EcsInstance: 'EcsInstance',
  RdsInstance: 'RdsInstance',
  RedshiftInstance: 'RedshiftInstance',
  AriksaOutpost: 'Ariksa Outpost',
  NoSql: 'NoSQL',
  DebianLinux: 'Debian GNU/Linux',
  RedHatEnterpriseLinux: 'Red Hat Enterprise Linux',
  RedHat: 'Red Hat',
  Ubuntu: 'Ubuntu',
  Region: 'Region',
  Vmware: 'vmware',
  AmazonLinux: 'Amazon Linux',
  Python: 'Python',
  ExclamationTriangle: 'ExclamationTriangle',
  Suppress: 'Suppress',
  SuppressAlarm: 'SuppressAlarm',
  Blueprint: 'Blueprint',
  BlueprintView: 'BlueprintView',
  Egress: 'Egress',
  Ingress: 'Ingress',
  Pii: 'Pii',
  EventLog: 'EventLog',
  CloudService: 'CloudService',
  Permission: 'Permission',
  Internet: 'Internet',
  Resource: 'Resource',
  Crown: 'Crown',
  Technology: 'Technology',
  OperatingSystem: 'OS',
  Ami: 'Ami',
  Packages: 'Packages',
  Distribution: 'Distribution',
  Environment: 'Environment',
  Notification: 'Notification',
  DataScan: 'DataScan',
  Vulnerability: 'Vulnerability',
  TrustedEntities: 'TrustedEntities',
  CloudInventory: 'Cloud Inventory',
  ContainerVolume: 'ContainerVolume',
  ContainerRepository: 'ContainerRepository',
  ContainerVulnerability: 'ContainerVulnerability',
  SeverityCritical: 'SeverityCritical',
  SeverityMedium: 'SeverityMedium',
  SeverityLow: 'SeverityLow',
  NetworkMap: 'NetworkMap',
  EcrImage: 'ECRImage',
  ResourceType: 'Resource Type',
  AuthGraph: 'AuthGraph',
  IndividualAlerts: 'IndividualAlerts',
  Evidence: 'Evidence',
  ZoomIn: 'ZoomIn',
  ZoomOut: 'ZoomOut',
  VpcGateway: 'VpcGateway',
  CloudActivity: 'CloudActivity',
  Interface: 'Interface',
  Alert: 'Alert',
  Route: 'Route',
  CommandLine: 'CommandLine',
  BlueprintException: 'BlueprintException',
  Download: 'Download',
  LateralMovement: 'LateralMovement',
  Login: 'Login',
  PrefixList: 'PrefixList',
  PrincipalAccess: 'PrincipalAccess',
  TrustedEntity: 'TrustedEntity',
  ViewAll: 'ViewAll',
  Clock: 'Clock',
  Label: 'Label',
  Tag: 'Tag',
  Hacker: 'Hacker',
  QuestionMark: 'QuestionMark',
  Context: 'Context',
  ContextLabel: 'ContextLabel',
  Console: 'Console',
  Whitelist: 'Whitelist',
  Metadata: 'Metadata',
  Exception: 'Exception',
  Checklist: 'Checklist',
  Dependencies: 'Dependencies',
  BarChart: 'BarChart',
  LineChart: 'LineChart',
  Sms: 'Sms',
  Sso: 'Sso',
  Ticket: 'Ticket',
  AzureActiveDirectory: 'AzureActiveDirectory',
  DataSources: 'DataSources',
  IdentityAndAccess: 'IdentityAndAccess',
  Search: 'Search',
  Remediate: 'Remediate',
  Details: 'Details',
  Timeline: 'Timeline',
  Disable: 'Disable',
  VirtualMachines: 'Virtual Machines',
  DataClassification: 'DataClassification',
  AriksaBestPractices: 'Ariksa Best Practices',
  AriksaLogo: 'AriksaLogo',
  AriksaLogoOnly: 'AriksaLogoOnly',
  CloudChart: 'CloudChart',
  RegexDataClassifier: 'RegexDataClassifier',
  Remove: 'Remove',
  ComplianceEnforcedChecks: 'ComplianceEnforcedChecks',
  CompliancePassedChecks: 'CompliancePassedChecks',
  ComplianceFailedChecks: 'ComplianceFailedChecks',
  ComplianceCheckExceptions: 'ComplianceCheckExceptions',
  ComplianceResource: 'ComplianceResource',
  HistoricalTimeline: 'HistoricalTimeline',
  IdentityAndAccessManagement: 'Identity and Access Management',
  DataRecovery: 'Data Recovery',
  'SimpleStorageService(S3)': 'Simple Storage Service (S3)',
  'Relational Database Service (RDS)': 'Relational Database Service (RDS)',
  Networking: 'Networking',
  AWSWhite: 'AWSWhite',
  Home: 'Home',
  Registry: 'Registry',
  Cve: 'cve',
  ContainersOnVirtualMachine: 'ContainersOnVirtualMachine',
  azuread: 'azuread',
  Automation: 'Automation',
  Workflow: 'Workflow',
  List: 'List',
  Summary: 'Summary',
  Overview: 'Overview',
  CloudPosture: 'Cloud Posture',
  DataSecurity: 'Data Security',
  IdentityAuthorization: 'Identity Authorization',
  UserCanAccess: 'UserCanAccess',
  BurgerMenu: 'BurgerMenu',
  DataTypes: 'DataTypes',
  DataCloud: 'DataCloud',
  IdentityCloud: 'IdentityCloud',
  Route53SubDomain: 'Route53SubDomain',
  Snooze: 'Snooze',
  Service: 'Service',
  SIEM: 'SIEM',
  Rules: 'Rules',
  Attach: 'Attach',
  Detach: 'Detach',
  RepoBranch: 'RepoBranch',
  Copy: 'Copy',
  Pod: 'Pod',
  Namespace: 'Namespace',
  Cloud: 'Cloud',
  Process: 'Process',
  CloudSlash: 'CloudSlash',
  EnforcedPolicies: 'EnforcedPolicies',
  PolicyResourceException: 'PolicyResourceException',
  PolicyException: 'PolicyException',
  OutpostForVulnerability: 'OutpostForVulnerability',
  AllFindings: 'AllFindings',
  Dashboard: 'Dashboard',
  DatabaseSecurity: 'DatabaseSecurity',
  DATA_AT_RISK: 'DATA_AT_RISK',
  DATA_ENCRYPTION: 'DATA_ENCRYPTION',
  NETWORK_MISCONFIGURATION: 'NETWORK_MISCONFIGURATION',
  DATA_RECOVERY: 'DATA_RECOVERY',
  POOR_GOVERNANCE: 'POOR_GOVERNANCE',
  INSECURE_CREDENTIALS: 'INSECURE_CREDENTIALS',
  INTERNET_EXPOSURE: 'INTERNET_EXPOSURE',
  EXCESS_PERMISSIONS: 'EXCESS_PERMISSIONS',
  UNUSED_ENTITIES: 'UNUSED_ENTITIES',
  PRIVILEGE_ESCALATION: 'PRIVILEGE_ESCALATION',
  ANOMALOUS_ACTIVITY: 'ANOMALOUS_ACTIVITY',
  SEPARATION_OF_DUTIES: 'SEPARATION_OF_DUTIES',
  VULNERABILITIES: 'VULNERABILITIES',
  POOR_AUDITABILITY: 'POOR_AUDITABILITY',
  Comment: 'Comment',
  CheckmarkCircle: 'CheckmarkCircle',
  ThirdParty: 'ThirdParty',
  NewFinding: 'NewFinding',
  ContainerImages: 'ContainerImages',
  AnomalousBehaviour: 'Anomalous Behaviour',
  Health: 'Health',
  PII: 'PII',
  PHI: 'PHI',
  PCI: 'PCI',
  SENSITIVE: 'SENSITIVE',
  Sensitive: 'Sensitive',
  AWS: 'AWS',
  GCP: 'GCP',
  Okta: 'Okta',
  Snowflake: 'Snowflake',
  GitLab: 'GitLab',
  GitHub: 'GitHub',
  BitBucket: 'BitBucket',
  Port: 'Port',
  Globe: 'Globe',
  LockOpen: 'LockOpen',
  GraphIncrease: 'GraphIncrease',
  GraphDecrease: 'GraphDecrease',
  CloseCircle: 'CloseCircle',
  Actions: 'Actions',
  BucketDeny: 'BucketDeny',
  Calendar: 'Calendar',
  Chat: 'Chat',
  DoubleTap: 'DoubleTap',
  Exclude: 'Exclude',
  Images: 'Images',
  VMImages: 'VMImages',
  Share: 'Share',
  ThirdPartyIntegration: 'ThirdPartyIntegration',
  ListOfInstances: 'List of instances',
  Jit: 'JIT',
  View: 'View',
  SyncRestart: 'SyncRestart',
  SyncPause: 'SyncPause',
  Informational: 'Informational',
  'Abnormal Behavior': 'Abnormal Behavior',
  'Elevated Risk': 'Elevated Risk',
  'Malicious Behavior': 'Malicious Behavior',
  'Malicious Access': 'Malicious Access',
  Vulnerabilities: 'Vulnerabilities',
  EOL: 'EOL',
  Centos: 'Centos',
  'Public IPs': 'Public IPs',
  'Public subnets': 'Public subnets',
  'Anomalous traffic': 'Anomalous traffic',
  'VMs with SSH open': 'VMs with SSH open',
};

export const IconTypes = {
  ...NativeResources,
  ...Resources,
  ...CustomIconTypes,
  ...Clients,
  ...Categories,
  ...ComplianceStandards,
  ...CloudProviders,
  ...SupportedServices,
  ...ContextType,
  ...ApplicationEnum,
  ...AccountType,
  ...RepositoryService,
  ...ReportTypes,
  ...CloudEnvironmentType,
};

export type AriksaIconMap = {
  [key in keyof typeof IconTypes | string]?: any;
};

export type IconType = typeof IconTypes | string;

export type IconSize = 'regular' | 'medium' | 'small' | 'xs' | 'sm' | 'md';
