import React from 'react';

import { Box } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { BsClock } from 'react-icons/all';

import { Select } from 'components/DataEntry';

export const publishedAtOptions = [
  { label: 'Latest', value: undefined },
  { label: 'Last day', value: 1 },
  { label: 'Last 7 days', value: 7 },
  { label: 'Last 15 days', value: 15 },
  { label: 'Last 30 days', value: 30 },
];

interface Props {
  historicalTime: Record<string, any>;

  setHistoricalTime(value: Record<string, any>);

  isDisabled?: boolean;
  width?: any;
  leftMessage?: string;
}

export const SelectHistoricalOptions = (props: Props) => {
  const {
    historicalTime,
    setHistoricalTime,
    isDisabled = false,
    width = 56,
    leftMessage,
  } = props;
  return (
    <Box w={width}>
      <Select
        options={publishedAtOptions}
        value={historicalTime}
        onChange={setHistoricalTime}
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
        isDisabled={isDisabled}
        valueContainerIcon={<BsClock />}
        leftMessage={leftMessage}
      />
    </Box>
  );
};

export const getPublishedTime = days => {
  return !!days ? dayjs().subtract(days, 'days').utc().format() : undefined;
};
